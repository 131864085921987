import { anyPass, pathEq } from 'ramda';

import { isOutputRepoReady } from '@/utils/comparators';
import { lineHeight } from '@/utils/constants';

import { getDisplayedCost } from './accessors';

export const scrollEventToUpdate = (target) => ({
   offset: Math.round(target.scrollTop / lineHeight),
   // The scrollIntoView function to pull us to the bottom is always short by 2 pixels
   atBottom: target.scrollHeight - target.scrollTop <= target.clientHeight + 2,
   container: target,
});

// If a job started (and thus has a cost), we can expect output files to be generated.
// In this case, keep polling until output repo is ready
export const stopFileRepositoryPolling = (job) =>
   anyPass([
      () => getDisplayedCost(job) === 0,
      isOutputRepoReady,
      pathEq('FRS_FileRepositoryNotFoundError', ['error', 'graphQLErrors', 0, 'extensions', 'serviceError', 'code']),
   ]);
