/* eslint-disable camelcase*/
import auth from '@simcenter-x/store/auth';
import { createLogoutUrl } from '@simcenter-x/store/auth/login';
import { path } from 'ramda';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { SwwcIcon, SwwcLink } from '@/swwc';

const regions = {
   'us-east-1': 'United States (N. Virginia)',
   'eu-central-1': 'Europe (Stockholm)',
   'ap-northeast-1': 'Asia Pacific (Tokyo)',
};

const getUserInfo = (token) => {
   const ecaId = path(['tenant', 'ecaId'], token);
   const region = path(['sws.samauth.tiers', '0', 'region'], token);
   return { ecaId, region: regions[region] };
};

function updateFontSize(email) {
   const emailContainerWidth = 240;
   const maxFontSize = 16;
   const minFontSize = 10;
   const fontRatio = 2;
   const emailLength = email?.length;

   const fontSize = Math.max(minFontSize, Math.min(maxFontSize, (emailContainerWidth / emailLength) * fontRatio));
   const emailElement = document.querySelector('.email');
   if (emailElement) {
      emailElement.style.fontSize = `${fontSize}px`;
   }
}

export const UserNavigationPanel = () => {
   const formRef = useRef(null);

   const {
      idTokenData: { email, family_name, given_name },
      accessTokenData,
      accessToken,
   } = auth.state.value;
   const { t } = useTranslation();

   const handleLogout = () => {
      formRef.current.submit();
      auth.clearData(false);
   };

   // Add a CSS rule to the shadow DOM of the swwc-primary-navigation-panel component
   const shadowRoot = document.querySelector('swwc-primary-navigation-panel')?.shadowRoot;
   if (shadowRoot) {
      const sheet = new CSSStyleSheet();
      sheet.replaceSync('.sandbox .swwc-panel--content { flex-grow: 1;}');
      shadowRoot.adoptedStyleSheets = [...shadowRoot.adoptedStyleSheets, sheet];
   }

   // Calculate the font size based on the email length and container width
   updateFontSize(email);

   const userInfo = getUserInfo(accessTokenData);

   return (
      <div className="flex flex-col h-full overflow-hidden justify-between">
         <div className="flex flex-col gap-2">
            <div className="flex flex-col items-center gap-2">
               <figure className="grid place-items-center bg-white rounded-full w-[120px] h-[120px] overflow-hidden mb-2">
                  <SwwcIcon name="cmdUser24" size="lg" />
               </figure>
               <div className="flex flex-wrap justify-center text-4xl text-center">
                  <span>
                     {given_name} {family_name}
                  </span>
               </div>
            </div>
            <span className="email">{email}</span>
            <div className="items-start mt-10">
               <ul className="ml-2 flex flex-col gap-4 items-start">
                  <li className="flex flex-col">
                     <span className="font-bold">{t('labels.ecaID')}:</span> <span>{userInfo.ecaId}</span>
                  </li>
                  <li className="flex flex-col">
                     <span className="font-bold">{t('labels.region')}:</span> <span>{userInfo.region}</span>
                  </li>
               </ul>
            </div>
         </div>
         <form method="POST" action={createLogoutUrl()} ref={formRef}>
            <input type="hidden" name="accessToken" value={accessToken} />
            <div className="ml-2 flex gap-1 pb-7 items-center">
               <SwwcIcon name="cmdSignout16" size="md" style={{ '--swwc-icon--color': '#FFFFFF' }} />
               <SwwcLink
                  text={t('commands.logOut')}
                  onClick={handleLogout}
                  style={{ '--swwc-link--color--normal--default--light': '#FFFFFF' }}
               />
            </div>
         </form>
      </div>
   );
};
