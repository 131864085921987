// @ts-nocheck
import { isNil, pick, propSatisfies, tap, unless } from 'ramda';

import { handleSubmitError } from '@/store/common/utils';
import { store } from '@/store/listing/state';
import services from '@/store/services';

import { getTemplateByMarketingVersion } from '@/utils/accessors';
import { dataStatePath, dataStates, jobTypeKeys } from '@/utils/constants';

import { trackSubmitNewRemoteSession } from '@/analytics/events';

import { model } from './model';
import { setTemplate } from './utils';

export function createJobInput(form, applicationTemplates) {
   const idempotencyToken = crypto.randomUUID();
   // NOTE: Temporarily defaulting to the first entry of application templates because:
   // - Remote desktop does not support version choice yet
   // - Current initial/default form version does not correspond with the remote desktop apps
   const app = getTemplateByMarketingVersion(form.version)(applicationTemplates) || applicationTemplates[0];
   const application = {
      type: app.type,
      version: app.version,
      precision: app.precision[0],
      typeHint: app.typeHint,
   };
   return {
      name: form.name.value,
      jobDefinition: {
         jobType: jobTypeKeys.remote,
         applications: [application],
         computeType: pick(['name', 'typeHint'], form.submissionTemplate.value),
      },
      idempotencyToken,
   };
}

export const rawActions = (present) => {
   return {
      safeSetTemplate: unless(propSatisfies(isNil, 'remote_desktop'), setTemplate(present)),

      initializeVersion: (version = '') => {
         present({ op: 'replace', value: version, path: '/form/version/value' });
      },

      changeFormValue: ({ key, value }) => {
         present({ op: 'replace', value, path: `/form/${key}/value` });
      },

      runSessionWorkflow: (client, form, applicationTemplates, seconds) => {
         return services
            .createJob(client, createJobInput(form, applicationTemplates))
            .then(tap(store.actions.addJob))
            .then(({ resourceId }) => {
               trackSubmitNewRemoteSession(window.San, { version: form.version, jobId: resourceId, seconds });
               services.submitJob(client, resourceId).catch(handleSubmitError(present)(resourceId, jobTypeKeys.remote));
               return resourceId;
            });
      },

      clear: () => {
         present({ op: 'replace', value: model, path: '' });
      },

      clearError: () => present({ op: 'replace', value: null, path: '/error' }),
      loadJobInfo: (client, resourceId) => {
         present({ op: 'replace', value: dataStates.loading, path: dataStatePath });
         return services
            .getJobDetails(client, resourceId)
            .then(({ jobDefinition }) => {
               present([
                  { op: 'replace', value: jobDefinition.computeType, path: '/details/submissionTemplate' },
                  { op: 'replace', value: dataStates.ready, path: dataStatePath },
               ]);
            })
            .catch(() => {
               present({ op: 'replace', value: dataStates.error, path: dataStatePath });
            });
      },
   };
};
