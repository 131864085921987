/**
 * @typedef {import('@/types').Application} Application
 */
import { isNotNil, propEq } from 'ramda';
import { useTranslation } from 'react-i18next';

import { jobTypesStore } from '@/store/jobtypes/store';
import { useStream } from '@/store/useStream';

import { withErrorBoundary } from '@/modules/common/ErrorBoundary';

import { getTemplateByMarketingVersion } from '@/utils/accessors';
import { updateValue } from '@/utils/jobSubmission';

import { SwwcRadioButton, SwwcRadioGroup } from '@/swwc';

/**
 * @typedef {Object} ComputeType
 * @property {string} name - The name of the compute type.
 * @property {number} hourlyCreditRate - The hourly credit rate of the compute type.
 */

/**
 * List of computeTypes (clusters) selectable by the user.
 * @typedef {Object} ComputeTypesProps
 * @property {ComputeType[]} [computeTypes] - The list of compute types.
 * @property {() => void} onChange - The function to call when the selection changes.
 * @property {ComputeType} selected - The selected compute type.
 * @property {number} version - selected version
 * @property {Application[]} applications - list of supported applications
 *
 * @param {ComputeTypesProps} props
 * @returns {React.ReactElement}
 */
export const ComputeTypes = withErrorBoundary(({ computeTypes = [], onChange, selected, version, applications }) => {
   const { t } = useTranslation();
   const { mappings } = useStream(jobTypesStore.state);
   const template = version ? getTemplateByMarketingVersion(version)(applications) : null;

   const isDisabled = (name) => isNotNil(template) && !template.computeTypes.some(propEq(name, 'name'));

   return (
      <ul className="flex flex-col gap-0.5">
         <SwwcRadioGroup
            orientation="vertical"
            variant="default"
            label={t('job.submissionTemplate')}
            value={selected?.name}
         >
            {computeTypes.map(({ name, hourlyCreditRate }) => {
               const translatedName = t(`computeTypes.${mappings[name]}`);
               const translatedCost = t(`job.computeTypeConsumption`, { rate: hourlyCreditRate });
               const label = `${translatedName} (${translatedCost}).`;

               return (
                  <li key={name}>
                     <SwwcRadioButton
                        label={label}
                        value={name}
                        name="clusterOptions"
                        className="mb-2"
                        onCheckedChange={updateValue(onChange)}
                        checked={name === selected?.name}
                        disabled={isDisabled(name)}
                     />
                  </li>
               );
            })}
         </SwwcRadioGroup>
      </ul>
   );
});
