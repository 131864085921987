import logoUrl from '/img/simcenter-x-logo.png';
import auth from '@simcenter-x/store/auth';
import { createLogoutUrl } from '@simcenter-x/store/auth/login';
import { useTranslation } from 'react-i18next';

import { productBrand } from '@/utils/constants';

import { SwwcApplicationFrame, SwwcButton, SwwcPrimaryNavigationBar } from '@/swwc';

function getCode() {
   const params = new URL(document.location).searchParams;
   return params.get('code');
}

export const Unauthorized = () => {
   const { accessToken } = auth.state.value;
   const { t } = useTranslation();
   const translateKey = getCode() === '401' ? 'common.invalidSession' : 'common.unauthorized';

   return (
      <SwwcApplicationFrame app-name={productBrand}>
         <SwwcPrimaryNavigationBar slot="primary-navigation" hideCommandItemsLabel hideAccountIcon>
            <img src={logoUrl} slot="edge-icon-top" width="32" alt="Simcenter X" />
         </SwwcPrimaryNavigationBar>
         <main slot="content" className="grid items-center h-full">
            <section className="m-auto flex flex-col items-center gap-4">
               <h1>{t(translateKey)}</h1>
               <form method="POST" action={createLogoutUrl()} onSubmit={() => auth.clearData(false)}>
                  <input type="hidden" name="accessToken" value={accessToken} />

                  <SwwcButton
                     type="submit"
                     label={t('commands.logOut')}
                     size="sm"
                     variant="accent-high-contrast"
                  ></SwwcButton>
               </form>
            </section>
         </main>
      </SwwcApplicationFrame>
   );
};
