/**
 * Reusable Cell component for table cells
 * @typedef {Object} CellProps - Component props
 * @property {React.ReactNode} children - Child elements to render inside the cell
 * @property {string} [textStyle=''] - Additional text styles
 * @property {string} [cellStyle=''] - Additional cell styles
 * @property {() => void} [onClick]
 * @property {string} [title]
 *
 * @param {CellProps} prop
 * @returns {React.ReactElement} A table cell component
 */
export const Cell = ({ children, textStyle = '', cellStyle = '', ...props }) => (
   <td {...props} className={`cursor-pointer break-all ${cellStyle}`}>
      <span className={`flex items-center ${textStyle}`}>{children}</span>
   </td>
);
