/**
 * @typedef {import('@/types').InsufficientCreditsError} InsufficientCreditsError
 */
import { useTranslation } from 'react-i18next';

import { isInsufficientError } from '@/utils/comparators';

import { SwwcButton, SwwcModalDialog } from '@/swwc';

/**
 * Modal Component displayed when a submission fails due to insufficient credits
 * @typedef {Object} InsufficientCreditsModalProps
 * @property {InsufficientCreditsError} error
 * @property {() => void} onClose
 *
 * @param {InsufficientCreditsModalProps} props
 * @returns {React.ReactElement}
 */
export const InsufficientCreditsModal = ({ error, onClose }) => {
   const { t } = useTranslation();

   if (!isInsufficientError(error)) {
      return null;
   }

   const jobType = t(`jobTypeName.${error.jobType}`);

   return (
      <SwwcModalDialog
         dialogTitle={t('modal.InsufficientCreditsError.title', { jobType })}
         isModal
         opened
         type="danger"
         className="absolute whitespace-pre"
         onOpenedChange={onClose}
      >
         <p>{t('modal.InsufficientCreditsError.message', { jobType })}</p>
         <SwwcButton slot="actions" label={t('commands.close')} onClickEvent={onClose} />
      </SwwcModalDialog>
   );
};
