import {
   always,
   both,
   compose,
   has,
   ifElse,
   isEmpty,
   isNil,
   mergeDeepRight,
   path,
   pathEq,
   propEq,
   unless,
   when,
} from 'ramda';

import { transformState } from '@/store/listing/sortAndFilter';

import { findByResourceId, getStatus } from '@/utils/accessors';
import { isNilOrEmpty } from '@/utils/comparators';
import { computeTaskUnifiedStatus, pollDelay, statusOrder } from '@/utils/constants';
import { isOneJobPreparing, isOneSessionInProcess } from '@/utils/jobUtils';
import { delay } from '@/utils/utils';

const workspaceWasUpdated = both(propEq('replace', 'op'), propEq('/workspace', 'path'));

/*
   As we poll the workspace will also handle updates on the frontend exclusively,
   we need to compare jobs on polls to determine if we update the store or keep the values modified by the frontend
*/

const verifyDefaults = (current, proposed) => {
   // if current workspace has no jobs, overwrite with the proposed workspace
   if (isEmpty(current.workspace.jobs)) {
      return proposed;
   }

   /**
    * We iterate over the current list of jobs and compare with the proposed list:
    * - if the proposed job doesn't exist:
    *    - if the current is deleted we remove it from the list
    *    - otherwise keep the current
    * - otherwise compare both jobs to see which is the most "recent" based on status
    */
   const nextJobs = current.workspace.jobs.reduce((jobs, currentJob) => {
      const proposedJob = findByResourceId(currentJob.resourceId)(proposed.workspace.jobs);

      if (isNil(proposedJob)) {
         if (getStatus(currentJob) === computeTaskUnifiedStatus.Deleted) {
            return jobs;
         }

         jobs.push(currentJob);
         return jobs;
      }

      const proposedStatusWeight = statusOrder[getStatus(proposedJob)] ?? 0;
      const currentStatusWeight = statusOrder[getStatus(currentJob)] ?? 0;
      const jobToKeep = proposedStatusWeight >= currentStatusWeight ? proposedJob : currentJob;

      jobs.push(jobToKeep);
      return jobs;
   }, []);

   // We now need to check in the proposed list of there are jobs that did not exist in current, then add them
   proposed.workspace.jobs.forEach((proposedJob) => {
      if (!findByResourceId(proposedJob.resourceId)(nextJobs)) {
         nextJobs.push(proposedJob);
      }
   });

   return mergeDeepRight(proposed, { workspace: { jobs: nextJobs } });
};

const safeDeriveRunningSession = compose(unless(isNilOrEmpty, isOneSessionInProcess), path(['workspace', 'jobs']));
const safeDerivePreparingJobs = compose(unless(isNilOrEmpty, isOneJobPreparing), path(['workspace', 'jobs']));

const isPollRequired = both(pathEq('/workspace', ['proposal', 'path']), pathEq(true, ['context', 'poll']));
const getJobs = ({ context, actions }) => delay(pollDelay).then(() => actions.loadJobs(context.client, true));

/**
 * The nap is invoked after an action has run and updated the workspace; it is intended
 * for derived data nad follow on actions.
 * @param {*} param0
 */
export const nap = ({ proposal, actions, context, currentState, proposedState }) => {
   when(isPollRequired, getJobs)({ proposal, context, actions });
   const warnUserHPCJob = safeDerivePreparingJobs(proposedState);
   const runningSessions = safeDeriveRunningSession(proposedState);
   const updatedProposedState = mergeDeepRight(proposedState, {
      warnUserRemoteSession: { runningSessions: Array.isArray(runningSessions) ? false : runningSessions },
      warnUserHPCJob: Array.isArray(warnUserHPCJob) ? false : warnUserHPCJob,
   });

   const nextState = ifElse(
      workspaceWasUpdated,
      () => verifyDefaults(currentState, updatedProposedState),
      always(updatedProposedState),
   )(proposal);

   return mergeDeepRight(proposedState, transformState(has('workspace', nextState) ? nextState : updatedProposedState));
};
