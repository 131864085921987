/**
 * @typedef {import('@/types').File} File
 * @typedef {import('@/types').CallbackArgs} CallbackArgs
 * @typedef {import('@/types').OnSelectArgs} OnSelectArgs
 */
import { equals } from 'ramda';

import { fileStore } from '@/store/files/store';

import { isEmbedded } from '@/utils/embeddedMessage';
import { silentDownload } from '@/utils/filesUtils';

import { SwwcCheckbox } from '@/swwc';

import { Cell } from './Cell';
import { DownloadButton } from './DownloadButton';

/**
 * Renders a cell with a checkbox and download button for a file
 * @typedef {Object} DownloadButtonProps - The component props
 * @property {Object} cell - The cell object from react-table
 * @property {OnSelectArgs} onSelect - Function to handle file selection
 * @property {File[]} selected - Array of currently selected files
 * @property {CallbackArgs} onCancel - Function to handle download cancellation
 * @property {CallbackArgs} onPause - Function to handle download pause
 * @property {CallbackArgs} onResume - Function to handle download resume
 * @property {string} repositoryId - The ID of the repository
 * @property {Object} client - The client object
 * @property {Object} downloadPath - Object containing download paths for repositories
 *
 * @param {DownloadButtonProps} prop
 * @returns {React.ReactElement} The rendered DownloadCell component
 */

export function DownloadCell({
   cell,
   onSelect,
   selected,
   onCancel,
   onPause,
   onResume,
   repositoryId,
   client,
   downloadPath,
}) {
   const file = cell.row.original;

   const checkSelected = (file) => selected.some(equals(file));

   return (
      <Cell key={cell.id} textStyle="gap-2" cellStyle={isEmbedded() ? 'basis-[110px]' : 'basis-[70px]'}>
         <SwwcCheckbox onCheckChanged={onSelect(file)} checked={checkSelected(file)} id={file.filename} />
         <DownloadButton
            file={file}
            repositoryId={repositoryId}
            downloader={fileStore.actions.downloadFiles(client, silentDownload)}
            onCancel={onCancel}
            onPause={onPause}
            onResume={onResume}
            client={client}
            downloadPath={downloadPath}
         />
      </Cell>
   );
}
