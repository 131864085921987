import { compose, equals, filter, mergeRight, not } from 'ramda';

import { getStatus } from '@/utils/accessors';
import { computeTaskUnifiedStatus } from '@/utils/constants';

export const isNotDeleted = compose(not, equals(computeTaskUnifiedStatus.Deleted), getStatus);

/**
 * Updates the displayedJobs and filterOptions when any upstream modifier is updated
 * @param {*} proposedState
 */
export const transformState = (proposedState) => {
   const existingJobs = filter(isNotDeleted, proposedState.workspace.jobs);
   const existingLength = existingJobs.length;
   const totalDisplayedJobs = existingJobs.length;

   return mergeRight(proposedState, {
      displayedJobs: existingJobs,
      totalDisplayedJobs,
      totalExistingJobs: existingLength,
   });
};
