import { path } from 'ramda';
import { useTranslation } from 'react-i18next';

import { hpcSubmissionStore } from '@/store/hpcJob/submission/store';
import { useStream } from '@/store/useStream';

import { precisions } from '@/utils/constants';
import { isPrecisionDisabled, updateValue } from '@/utils/jobSubmission';

import { SwwcRadioButton, SwwcRadioGroup } from '@/swwc';

/**
 * Component that render the selectable list of versions
 * @typedef {Object} PrecisionSelcetorProps
 * @property {string} selectedPrecision - selected precisin
 * @property {string[]} supportedPrecisions - supported precision
 * @property {number} selectedVersion - selected version
 *
 * @param {PrecisionSelcetorProps} props
 * @returns {React.ReactElement}
 */
export function PrecisionSelector({ selectedPrecision, supportedPrecisions = [], selectedVersion }) {
   const { t } = useTranslation();
   const state = useStream(hpcSubmissionStore.state);
   const versionError = path(['errors', 'version'], state);

   return (
      <SwwcRadioGroup orientation="vertical" variant="default" label={t('labels.precision')}>
         {precisions.map((precision) => (
            <SwwcRadioButton
               key={precision}
               name="precisionRadioGroup"
               label={t(`precision.${precision}`)}
               value={precision}
               disabled={versionError || isPrecisionDisabled(selectedVersion, supportedPrecisions, precision)}
               checked={selectedPrecision === precision}
               onCheckedChange={updateValue(hpcSubmissionStore.actions.setPrecision)}
            />
         ))}
      </SwwcRadioGroup>
   );
}
