import { dataStates } from '@/utils/constants';

/**
 * Store will contain dynamic keys (job ids), then keep information on files for
 * each job and their upload progress
 */
export const model = {
   submittedFiles: {},
   files: [],
   filtered: [],
   fileRepository: {
      dataState: {
         input: dataStates.loading,
         output: dataStates.loading,
      },
      input: {
         files: [],
         id: '',
         status: '',
      },
      output: {
         files: [],
         id: '',
         status: '',
      },
   },
   preview: {
      image: null,
      state: 'READY',
   },
   searchTerm: '',
   displayedFiles: [],
   totalDisplayedFiles: 0,
   // embedded mode stuff
   downloadInfo: {},
   overallDownloadInfo: {},
   downloadPath: {},
};
