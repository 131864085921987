// @ts-nocheck
import { compose, defaultTo, evolve, ifElse, map, mergeDeepLeft, propEq, subtract, when } from 'ramda';

import { getRuntime } from './accessors';
import { isJobType } from './comparators';
import { devV2marketingV, fileStatus, jobTypeKeys, millesondsInADay } from './constants';

export const createUrl = ifElse(
   isJobType(jobTypeKeys.hpc),
   ({ resourceId }) => `/hpcJob/${resourceId}`,
   ({ resourceId }) => `/remoteDesktop/${resourceId}`,
);

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export function getBaseName(filename) {
   if (filename) {
      return filename.replace(/^.*[\\/]/, '');
   }
   return '';
}
export const decodeToNum = compose(defaultTo(0), parseInt, atob);

export const updateJobs = (job) => map(when(propEq(job.resourceId, 'resourceId'), mergeDeepLeft(job)));

export const applyToJobsInWorkspace = (jobTransformer) => evolve({ workspace: { jobs: map(jobTransformer) } });

const formatWithUnits = (input, units, k, decimals = 1) => {
   if (!input) {
      return `0 ${units[0]}`;
   }

   const i = Math.min(Math.floor(Math.log(input) / Math.log(k)), units.length - 1);

   return `${parseFloat((input / Math.pow(k, i)).toFixed(decimals))} ${units[i]}`;
};

export const formatBytes = (bytes) => {
   return formatWithUnits(bytes, ['Bytes', 'KB', 'MB', 'GB'], 1024);
};

export const formatSpeed = (bytes) => {
   return formatWithUnits(bytes, ['B/s', 'KB/s', 'MB/s', 'GB/s'], 1024);
};

export const formatTime = (time) => {
   return formatWithUnits(time, ['seconds', 'minutes', 'hours'], 60);
};

export const convertToPercent = (loaded, total) => Math.floor((loaded * 100) / total);
export const getUploadInfoUpdate = ({ total, loaded, elapsedSeconds }) => {
   const uploadRate = elapsedSeconds > 0 ? loaded / elapsedSeconds : 0;
   const remainingTime = uploadRate > 0 ? (total - loaded) / uploadRate : 0;
   const progress = convertToPercent(loaded, total);
   const status = progress === 100 ? fileStatus.completed : fileStatus.progressing;

   return {
      total,
      loaded,
      remainingTime,
      progress,
      status,
      rate: uploadRate,
   };
};
export const removeTrailingSlash = (str) => str.replace(/\/$/, '');

export const addPrecedingSlash = (uri) => {
   if (!uri.startsWith('/')) {
      return `/${uri}`;
   }
   return uri;
};

const addSubsequentSlash = (uri) => {
   if (!uri.endsWith('/')) {
      return `${uri}/`;
   }
   return uri;
};

export const formatBaseUrl = compose(addSubsequentSlash, addPrecedingSlash);

export const getOssUrl = () => `${removeTrailingSlash(import.meta.env.BASE_URL)}${import.meta.env.VITE_README_OSS}`;

export const decimalToTime = (job) => {
   const runtime = getRuntime(job);
   const hours = runtime ? Math.floor(runtime) : 0;
   const minutes = Math.floor((runtime - hours) * 60);

   return { hours, minutes };
};

export const formattedTime = (submissionTime, today) => {
   const submittedDate = submissionTime ? new Date(submissionTime) : null;

   if (!submittedDate) {
      return { translationKey: null, time: 'N/A' };
   }

   const elapsedTime = subtract(today.getTime(), submittedDate.getTime());
   if (elapsedTime <= millesondsInADay && today.getDate() === submittedDate.getDate()) {
      return {
         translationKey: 'todaySubmittedTime',
         time: new Intl.DateTimeFormat(undefined, { timeStyle: 'short' }).format(submittedDate),
      };
   }

   if (elapsedTime <= millesondsInADay * 2 && today.getDate() === submittedDate.getDate() + 1) {
      return {
         translationKey: 'yesterdaySubmittedTime',
         time: new Intl.DateTimeFormat(undefined, { timeStyle: 'short' }).format(submittedDate),
      };
   }

   return {
      translationKey: null,
      time: new Intl.DateTimeFormat(undefined, { dateStyle: 'short', timeStyle: 'medium' }).format(submittedDate),
   };
};

export const formatRunTime = (job, t) => {
   const { hours, minutes } = decimalToTime(job);

   if (hours === 0 && minutes === 0 && job.debitReport?.amount > 0) {
      return t('job.runTime.seconds');
   }

   return `${hours ? t('job.runTime.hours', { count: hours }) : ''} ${t('job.runTime.minutes', { count: minutes })}`;
};

export const extractVersion = (version) => {
   const [major, minor] = version.split('.');

   const majorNum = Number(major);
   const minorNum = Number(minor);

   const minorV = devV2marketingV[minorNum];
   if (minorV) {
      return (majorNum + 5) * 100 + minorV; // 18.03 becomes 2306, 17.06 becomes 2210, 7.06 becomes 1210 etc
   }

   return 0;
};
