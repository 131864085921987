// @ts-nocheck
import { always, both, compose, endsWith, ifElse, isNil, isNotNil, prop } from 'ramda';

import { extractVersion } from './formatters';
import { readInFile } from './readFile';

const versionAndPrecisionRegex = /'(BuildEnv|PresentationVersion|ReleaseNumber)': '(\S+)'/g;
const statePositionNumberRegex = /'StatePosition': (\d{1,20})/;

const extractStatePos = (content) => {
   const match = content.match(statePositionNumberRegex);

   if (isNil(match) || match.length < 2) {
      throw new Error('StatePosition not found');
   }

   return parseInt(match[1]);
};

const getPrecision = compose(
   ifElse(both(isNotNil, endsWith('-r8')), always('double'), always('mixed')),
   prop('BuildEnv'),
);

const getVersion = ({ PresentationVersion, ReleaseNumber }) => {
   if (isNotNil(PresentationVersion) && isFinite(Number(PresentationVersion))) {
      return parseFloat(PresentationVersion);
   }

   return isNotNil(ReleaseNumber) ? extractVersion(ReleaseNumber) : 0;
};

const extractVersionAndPrecision = (content) => {
   const values = {
      BuildEnv: null,
      ReleaseNumber: null,
      PresentationVersion: null,
   };

   const results = [...content.matchAll(versionAndPrecisionRegex)];

   results.forEach(([, key, value]) => {
      values[key] = value;
   });

   return values;
};

/*
 *  Get the ReleaseNumber - first try to get the PresentationVersion directly,
 *  if it is not there, or it has an old syntax, fall back to ReleaseNumber e.g. {'BuildArch': 'linux-x86_64-2.17',
 *  'BuildEnv': 'gnu11.2-r8', 'ClassName': 'StarVersion', 'MachineConfig': 'Serial', 'PresentationVersion': '2306',
 *  'ReleaseDate': 'Wed Feb 15 02:15:53 UTC 2023', 'ReleaseNumber': '18.03.053'}
 */
const getVersionAndPrecision = (file) => (position) =>
   readInFile(file.slice(position, position + 300))
      .then(extractVersionAndPrecision)
      .then((values) => ({ precision: getPrecision(values), version: getVersion(values) }));

/*
 *  Get the StatePosition - this is showing us the position of the ReleaseNumber in the file
 *  e.g. {'Binary': '[][][]', 'ClassName': 'STAR', 'StatePosition': 2293627720L, 'Version': 3}
 */
const getStatePosition = (blob) => readInFile(blob).then(extractStatePos);

export const getVersionAndPrecisionFromFile = (file) => {
   return getStatePosition(file.slice(0, 100))
      .then(getVersionAndPrecision(file))
      .catch(always({ version: 0, precision: 'mixed' }));
};
