import { useTranslation } from 'react-i18next';

import { isEmbedded } from '@/utils/embeddedMessage';
import { getSortedIcon, renderHeaderLabel } from '@/utils/tableUtils';

/**
 * TableHeader component for rendering the header of the file table
 * @typedef {Object} TableHeaderProps
 * @property {Object} table - Table instance from useReactTable hook
 *
 * @param {TableHeaderProps} prop
 * @returns {React.ReactElement} The header section of the file table
 */

export function TableHeader({ table }) {
   const { t } = useTranslation();

   return (
      <thead className="bg-black-150 text-black-800 sticky top-0 z-10">
         {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
               {headerGroup.headers.map(({ column, id }) => {
                  const { header } = column.columnDef;
                  return (
                     <th
                        key={id}
                        data-accessor={header}
                        className={`${column.getCanSort() ? 'cursor-pointer' : ''} ${isEmbedded() ? 'basis-[110px]' : 'basis-[70px]'}`}
                        onClick={column.getToggleSortingHandler()}
                     >
                        {t(renderHeaderLabel(header))}
                        {column.getCanSort() && <span className="pl-3">{getSortedIcon(column)}</span>}
                     </th>
                  );
               })}
            </tr>
         ))}
      </thead>
   );
}
