// @ts-ignore
import auth from '@simcenter-x/store/auth';
import { useEffect } from 'react';
import { useParams } from 'react-router';

import { getNiceDCVUrlFromState } from '@/store/common/urls';

import { productBrand } from '@/utils/constants';

import { AuthContext } from '@/AuthContext';

export function Session() {
   const { sessionId } = useParams();
   const { accessToken } = auth.state.value;
   const gateway = getNiceDCVUrlFromState();
   useEffect(() => {
      document.title = `${productBrand}: ${window.simcenterXJobName || 'Remote Desktop session'}`;
   }, []);

   return (
      <AuthContext>
         <div className="h-[100vh]">
            <iframe
               id="remoteDesktopSessionFrame"
               title="Remote Desktop Session"
               width="100%"
               height="100%"
               allowFullScreen
               src={`${gateway}:8443?authToken=${encodeURIComponent(accessToken)}#${encodeURIComponent(sessionId)}`}
            />
         </div>
      </AuthContext>
   );
}
