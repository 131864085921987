import { SwwcLoadingIndicator } from '@/swwc';

/**
 * Component that displays the swwc loading indicator with defaults wrapping styles
 * @typedef {Object} LoadingIndicatorProps
 * @property {string} [size='medium'] - The size of the loading indicator
 * @property {string} [slot] - The slot to render the loading indicator in
 * @property {React.HTMLAttributes<HTMLDivElement>} [props] - Additional props
 *
 * @param {LoadingIndicatorProps} props
 * @returns {React.ReactElement}
 */
export const LoadingIndicator = ({ size = 'medium', ...props }) => {
   return (
      <div className="flex justify-center" {...props}>
         <SwwcLoadingIndicator type="circular" size={size} />
      </div>
   );
};
