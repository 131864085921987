/**
 * @type { RemoteModuleDefinitionList}
 */
export default [
   /**
    * Example of defining a remote
    */
   // {
   //    name: 'systems',
   //    entry: 'http://localhost:5002/remoteEntry.js',
   //    alias: 'systems',
   //    type: 'module',
   //    exposedModule: 'CatalogueView',
   //    excludeFromRoutes: false,
   // },
];
