/**
 * @typedef {import('@/types').JobLogsProps} JobLogsProps
 */
import { useEffect, useRef } from 'react';
import { useClient } from 'urql';

import { actions, state } from '@/store/logs/actions';
import { useStream } from '@/store/useStream';

import { LoadingIndicator } from '@/modules/common/LoadingIndicator';

import { dataStates } from '@/utils/constants';

import { Nodes } from './Node';

const handleVisibilityChange = () => actions.toggleTabVisibility(document.visibilityState === 'visible');

/**
 * Component that render a container that will display the output logs
 * @param {JobLogsProps} props
 * @returns {React.ReactElement}
 */
export const LogNodes = ({ task, outputRepoIsReady }) => {
   const client = useClient();
   const scrollRef = useRef(null);
   const { autoScroll, logsHeight, dataState, nodes, startCursor, isVisible } = useStream(state);
   const scrollContainerHeight = scrollRef.current?.offsetHeight;

   useEffect(() => {
      if (autoScroll) {
         scrollRef.current.scrollBy({ top: logsHeight, behaviour: 'instant' });
      }
   }, [autoScroll, logsHeight]);

   useEffect(() => {
      if (logsHeight <= scrollRef.current?.clientHeight) {
         setTimeout(() => actions.fetchLastLines(client, task), 5000);
      }
   }, [logsHeight]);

   useEffect(() => {
      if (task.id && task.unifiedStatus) {
         actions.initialize(client, task);
         document.addEventListener('visibilitychange', handleVisibilityChange);
      }

      return () => {
         actions.reset();
         document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
   }, []);

   useEffect(() => {
      // If the user switches browser tabs, the browser will suspend rendering of the current tab
      // this means that the `onScroll` handler will no longer be invoked and the polling of logs stops.
      // To ensure the logs are properly updated, when the user switches back to the current tab, we pick up
      // where we left off and fetch the last available lines.
      if (autoScroll && isVisible) {
         actions.fetchLastLines(client, task);
      }
   }, [isVisible]);

   useEffect(() => {
      // Fetch logs when the files are ready one last time, hoping to get the last lines
      // (or any lines at all if there were none before)
      if (dataState !== dataStates.loading && !nodes.length && outputRepoIsReady) {
         actions.fetchLastLines(client, task);
      }
   }, [outputRepoIsReady]);

   const onScroll = (e) => actions.updateScroll(client, task, autoScroll, e, scrollContainerHeight);

   return (
      <div className="relative grow flex flex-col pb-2">
         {dataState === dataStates.loading && (
            <div className="absolute top-0 w-full h-full z-10 grid items-center bg-black-50/[.5]">
               <LoadingIndicator size="xlarge" />
            </div>
         )}
         <div
            ref={scrollRef}
            onScroll={onScroll}
            data-cy="outputLog"
            className="grow shrink basis-auto overflow-y-scroll h-0 bg-black-50"
         >
            <div style={{ height: `${logsHeight}px` }} className="dark:bg-black-800 font-mono relative">
               <Nodes nodes={nodes} startCursor={startCursor} dataState={dataState} />
            </div>
         </div>
      </div>
   );
};
