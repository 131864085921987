import { gql } from '@urql/core';

export default gql`
   query GetUserBalance {
      creditData {
         user {
            balance
            effectiveBalance
            consumptionRate
         }
         account {
            balance
         }
      }
   }
`;
