/**
 * @typedef {import('@/types').File} File
 */
import { compose, propEq, reject } from 'ramda';

import { getReadableFileSize } from '@/utils/accessors';

import { SwwcIcon } from '@/swwc';

import { FileInput } from './FileInput';

/**
 * @typedef {Object} RenderProps
 * @property {File[]} value list of files
 * @property {() => void} onChange callback to update the list of files when removing one from the list
 */

/**
 * Renders a list of files
 * @param {RenderProps} props
 * @returns {React.ReactElement}
 */
const RenderFileList = ({ value, onChange }) => {
   const deleteOneFile = (name) => compose(onChange, reject(propEq(name, 'name')), Array.from);

   if (value.length > 0) {
      return (
         <ul className="overflow-auto max-h-44">
            {value.map(({ name, size }) => {
               return (
                  <li
                     className="flex justify-between items-center p-1 bg-black-100 border-b-2 border-green-500"
                     key={name}
                  >
                     <span className="font-bold block w-48 truncate" title={name}>
                        {name}
                     </span>
                     <div className="flex">
                        <span>({getReadableFileSize(size)})</span>
                        <SwwcIcon
                           onClick={() => deleteOneFile(name)(value)}
                           name="cmdClosePanel24"
                           size="sm"
                           className="cursor-pointer pl-1"
                        />
                     </div>
                  </li>
               );
            })}
         </ul>
      );
   }
   return null;
};

/**
 * File Input with a list of files displayed below the input.
 * @param {RenderProps & import('@/types').FileInputProps} props
 * @returns {React.ReactElement}
 */
export const MultipleFilesInput = (props) => {
   return (
      <FileInput {...props}>
         <RenderFileList {...props} />
      </FileInput>
   );
};
