import { dataStates, lineHeight } from '@/utils/constants';

/**
 * Component that render a output logs line
 * @typedef {Object} NodeProps
 * @property {number} line - current line index
 * @property {string} text - current line content
 *
 * @param {NodeProps} props
 * @returns {React.ReactElement}
 */
const Node = ({ line, text }) => {
   return (
      <div
         style={{
            lineHeight: `${lineHeight}px`,
            height: lineHeight,
            top: `${line * lineHeight}px`,
         }}
         className="absolute pl-4"
      >
         <p className="relative dark:text-black-50 overflow-x-visible whitespace-pre">{text}</p>
      </div>
   );
};

/**
 * Component that render the list of output logs line
 * @typedef {Object} NodesProps
 * @property {string[]} nodes - list of output logs lines
 * @property {number} startCursor - first line displayed
 * @property {string} dataState - logs data state
 *
 * @param {NodesProps} props
 * @returns {React.ReactElement[] | React.ReactElement}
 */
export const Nodes = ({ nodes, startCursor, dataState }) => {
   if (!nodes.length && dataState === dataStates.ready) {
      return <span className="m-4">No logs to display</span>;
   }
   return nodes.map((node, i) => {
      const lineNo = startCursor + i + 1;
      return <Node line={lineNo} key={lineNo} text={node} />;
   });
};
