/**
 * @typedef {import('@/types').File} File
 * @typedef {import('@/types').CallbackArgs} CallbackArgs
 * @typedef {import('@/types').OnSelectArgs} OnSelectArgs
 */
import { TableRow } from './TableRow';

/**
 * TableBody component for rendering the body of the file table
 * @typedef {Object} TableBodyProps
 * @property {Object} table - Table instance from useReactTable hook
 * @property {OnSelectArgs} onSelect - Function to handle file selection
 * @property {File[]} selected - Array of currently selected files
 * @property {CallbackArgs} onCancel - Function to handle cancellation of file operations
 * @property {CallbackArgs} onPause - Function to handle pausing of file operations
 * @property {CallbackArgs} onResume - Function to handle resuming of file operations
 * @property {Object} client - URQL client instance
 * @property {string} repositoryId - ID of the current repository
 * @property {Object} downloadPath - Object containing download paths
 *
 * @param {TableBodyProps} prop
 * @returns {React.ReactElement} The body section of the file table
 */
export function TableBody({
   table,
   onSelect,
   selected,
   onCancel,
   onPause,
   onResume,
   client,
   repositoryId,
   downloadPath,
}) {
   return (
      <tbody>
         {table.getRowModel().rows.map((row) => (
            <TableRow
               key={row.id}
               row={row}
               onPause={onPause}
               onResume={onResume}
               onCancel={onCancel}
               onSelect={onSelect}
               selected={selected}
               repositoryId={repositoryId}
               client={client}
               downloadPath={downloadPath}
            />
         ))}
      </tbody>
   );
}
