import { loadRemote } from '@module-federation/enhanced/runtime';
import React, { createElement, lazy, useEffect, useState } from 'react';

import { LoadingIndicator } from '@/modules/common/LoadingIndicator.jsx';

import { SwwcInformationBar } from '@/swwc';

function RemoteError({ error }) {
   return (
      <div className="flex flex-col gap-2 items-center justify-center h-screen w-screen text-2xl">
         <div>The remote module could not be loaded</div>
         <SwwcInformationBar variant="error" useIcon informationText={error} />
      </div>
   );
}

/**
 * A small wrapper for 'remote components' (i.e. microfrontends).
 * To use, you would import it in the file while the microfrontend should be mounted, e.g. Routes.jsx:
 * import { RemoteWebComponent } from '@/remoteModules/RemoteModule.jsx';
 *
 * Then use it as a standard react component:
 * <RemoteModule path="app2/scx-mife2" name="scx-mife2" />,
 *
 * @typedef {object} RemoteModuleProps
 * @property {string} modulePath String pointing to the remote module to import.
 * This would be the `alias` or `name` declared in the remotes file plus the name of the module that is exposed.
 * I.e "{alias}/{module}"
 * @property {string} name The tag name of the web component to mount, e.g. `simx-app`.
 *
 * @param {RemoteModuleProps} props
 * @returns {JSX.Element}
 */
export function RemoteModule({ modulePath, name }) {
   const [loading, setLoading] = useState(true);
   useEffect(() => {
      loadRemote(modulePath).then(() => {
         setLoading(false);
      });
   }, []);
   if (loading) {
      return <LoadingIndicator type="circular" style={{ width: '100%' }} />;
   }
   return createElement(name, {});
}

export function RemoteComponent({ modulePath }) {
   const Component = lazy(() =>
      loadRemote(modulePath).catch((err) => {
         console.error(err);
         return { default: () => <RemoteError error={err.message} /> };
      }),
   );
   return (
      <React.Suspense fallback={<LoadingIndicator />}>
         <Component />
      </React.Suspense>
   );
}
