import { useTranslation } from 'react-i18next';

import { store } from '@/store/listing/state';
import { useStream } from '@/store/useStream';

import { SwwcInformationBar } from '@/swwc';

const warnLabel = 'warning.label';

/**
 * Information bar displayed when a session is running and when the user has not disabled prior warnings
 * @typedef {Object} InfoBarWarningProps
 * @property {string} className - Tailwind classes to apply to the wrapper component
 *
 * @param {InfoBarWarningProps} props
 * @returns {React.ReactElement}
 */
export const WarnSessionIsInProcess = ({ className }) => {
   const { t } = useTranslation();
   const {
      warnUserRemoteSession: { runningSessions, userWantsToBeWarned },
   } = useStream(store.state);

   if (!runningSessions || !userWantsToBeWarned) {
      return null;
   }

   return (
      <div className={className}>
         <SwwcInformationBar
            informationText={t('warning.executingRDWarning')}
            variant="warning"
            useIcon
            closable
            label={t(warnLabel)}
            onCloseEvent={() => store.actions.setWarnUserInfo(false)}
         />
      </div>
   );
};

/**
 * Information bar displayed when a job has been submitted and is currently processed until final submission
 * @param {InfoBarWarningProps} props
 * @returns {React.ReactElement}
 */
export const WarnHPCPreparing = ({ className }) => {
   const { t } = useTranslation();
   const { warnUserHPCJob } = useStream(store.state);

   if (!warnUserHPCJob) {
      return null;
   }

   return (
      <div className={className}>
         <SwwcInformationBar
            informationText={t('warning.submissionInProgress')}
            variant="warning"
            useIcon
            label={t(warnLabel)}
         />
      </div>
   );
};

/**
 * Information bar displayed when a job has been corrupted during submission and will remain PENDING
 * @param {InfoBarWarningProps} props
 * @returns {React.ReactElement}
 */
export const WarnInvalidStatus = ({ className }) => {
   const { t } = useTranslation();

   return (
      <div className={className}>
         <SwwcInformationBar
            informationText={t('errors.invalidStatus')}
            variant="warning"
            useIcon
            label={t(warnLabel)}
         />
      </div>
   );
};
