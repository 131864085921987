/**
 * @typedef {import('@/types').Job} Job
 */
import {
   compose,
   filter,
   find,
   ifElse,
   isEmpty,
   isNil,
   isNotNil,
   path,
   pick,
   prop,
   propEq,
   propOr,
   unless,
} from 'ramda';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useClient } from 'urql';

import { fileStore } from '@/store/files/store';
import { jobDetailsStore } from '@/store/hpcJob/store';
import { jobTypesStore } from '@/store/jobtypes/store';
import { useStream } from '@/store/useStream';

import { getTask } from '@/utils/accessors';
import { isInRange, isNilOrEmpty, isNotNilOrEmpty } from '@/utils/comparators';
import { computeTaskUnifiedStatus, dataStates, statusOrder } from '@/utils/constants';
import { formatRunTime } from '@/utils/formatters';

import { SwwcLoadingIndicator, SwwcProgressBar } from '@/swwc';

const getHint = ({ progress, loaded, total, remainingTime, rate }) =>
   loaded && isInRange(progress) ? `${loaded}/${total}, ${remainingTime} left (${rate})` : '';

/**
 * Wrapper component for displaying the filename and progress of a file while upload is happening.
 * @typedef {"progressing" | "paused" | "completed" | "error" | "terminated"} Status
 * @typedef {Object} Upload
 * @property {Status} status - job status
 * @property {number} progress - current file upload progress
 * @property {string} errorMessage - error message
 * @property {boolean} loaded - size of the file already upload
 * @property {number} total - total size to upload
 * @property {number} remainingTime - remaining time before end of upload
 * @property {number} rate - upload speed
 *
 * @typedef {Object} FileNameAndProgressProps
 * @property {string} datacy - Attribute to facilitate targetting in e2e
 * @property {string} name - file name
 * @property {Upload} upload - file upload information
 *
 * @param {FileNameAndProgressProps} props
 * @returns {React.ReactElement}
 */
const FileNameAndProgress = ({ datacy, name, upload }) => {
   return (
      <>
         <p data-cy={datacy} className="break-words max-w-96">
            {name}
         </p>
         {isNotNilOrEmpty(upload) && upload?.status !== 'terminated' && (
            <SwwcProgressBar
               status={upload.status}
               progress={upload.progress}
               hint={getHint(upload)}
               errorMessage={upload.errorMessage}
               className="w-96 md:w-full"
            />
         )}
      </>
   );
};
const LoadingOrFilename = ifElse(compose(isNotNil, prop('name')), FileNameAndProgress, () => (
   <SwwcLoadingIndicator type="circular" size="xxsmall" />
));

const filterFiles = (key1, key2, obj) =>
   filter((file) => file.name !== '' && file.name !== obj[key1] && file.name !== obj[key2]);

const getFilesAndKey = (key) => pick(['files', key]);
const findFile = (key) => (obj) => find(propEq(obj[key], 'name'), obj.files);
const getFile = (key) => compose(unless(isNilOrEmpty, findFile(key)), getFilesAndKey(key));

const hasUploaded = (status) =>
   statusOrder[status] > statusOrder[computeTaskUnifiedStatus.Uploading] || status === computeTaskUnifiedStatus.Queued;

/**
 * Appends the different files (simulation, macro, and others) to the job information sidebar
 * @typedef {Object} JobInfoFilesProps
 * @property {Job} job
 *
 * @param {JobInfoFilesProps} prop
 * @returns {React.ReactElement}
 */
const JobInfoFiles = ({ job }) => {
   const client = useClient();
   const { t } = useTranslation();
   const { submittedFiles, fileRepository } = useStream(fileStore.state);
   const { productInformation } = useStream(jobDetailsStore.state);
   const id = job.resourceId;
   const task = getTask(job);
   const inputFiles = submittedFiles[id];
   const inputFileRepositoryUrn = path(['task', 'inputFileRepository', 'urn'], productInformation);

   useEffect(() => {
      if (hasUploaded(task.unifiedStatus) && isNotNil(inputFileRepositoryUrn) && isEmpty(fileRepository.input.files)) {
         fileStore.actions.loadJobInputFiles(client, inputFileRepositoryUrn, job);
      } else if (isNilOrEmpty(inputFiles)) {
         fileStore.actions.setJobFiles({ inputFiles: [], taskName: task.name, id });
         return () => fileStore.actions.clearSubmittedFilesById(id);
      }
   }, [task.unifiedStatus, inputFileRepositoryUrn]);

   const sim = inputFiles ? getFile('sim')(inputFiles) : null;
   const macro = inputFiles ? getFile('macro')(inputFiles) : null;
   const others = inputFiles ? compose(filterFiles('sim', 'macro', inputFiles), propOr([], 'files'))(inputFiles) : null;

   return (
      <>
         <li>
            <h3 className="text-base font-semibold">{t('job.simulationFile')}:</h3>
            <LoadingOrFilename datacy="simulationFile" name={sim?.name} upload={sim?.upload} />
         </li>
         {!isNilOrEmpty(macro) && (
            <li>
               <h3 className="text-base font-semibold">{t('job.macro')}:</h3>
               <FileNameAndProgress datacy="macroFile" name={macro.name} upload={macro?.upload} />
            </li>
         )}
         {!isNilOrEmpty(others) && (
            <li>
               <h3 className="text-base font-semibold">{t('job.otherFiles')}:</h3>
               {others.map((file) => {
                  return (
                     <FileNameAndProgress
                        datacy="otherFile"
                        name={file.name}
                        upload={file?.upload}
                        key={`otherFile-${file.name}`}
                     />
                  );
               })}
            </li>
         )}
      </>
   );
};

/**
 * Display the job's selected version and precision once the information is available, otherwise loader
 * @typedef {Object} VersionDisplayProps
 * @property {Job} job - current job
 * @property {Record<string, string>} mappings - versions, job types and culters list
 * @property {string} jobTypeState - job types data state
 *
 * @param {VersionDisplayProps} props
 * @returns {JSX.Element}
 */
const VersionDisplay = ({ job, mappings, jobTypeState }) => {
   const { t } = useTranslation();

   if (jobTypeState === dataStates.loading) {
      return (
         <li>
            <h3 className="text-base font-semibold">{t('labels.version')}:</h3>
            <SwwcLoadingIndicator type="circular" size="xxsmall" />
         </li>
      );
   }

   const application = path(['jobDefinition', 'applications', '0'], job);
   const version = prop(prop('version', application), mappings);
   const precision = prop('precision', application);

   return (
      <li>
         <h3 className="text-base font-semibold">{t('labels.version')}:</h3>
         <span>
            {version === 'unknown'
               ? t('labels.unknownVersion')
               : t('labels.renderedVersion', {
                    version,
                    precision: t(`precision.${precision}`),
                 })}
         </span>
      </li>
   );
};

/**
 * List item to display the selected compute type for a job
 * @typedef {Object} ComputeTypeProps
 * @property {Record<string, string>} mappings - versions, job types and culters list
 *
 * @param {ComputeTypeProps} props
 * @returns {JSX.Element}
 */
const ComputeType = ({ mappings }) => {
   const { t } = useTranslation();
   const details = useStream(jobDetailsStore.state);
   const computeTypeName = path(['productInformation', 'computeTypeName'], details);

   if (isNil(mappings) || details.dataState === dataStates.loading) {
      return (
         <li>
            <h3 className="text-base font-semibold">{t('job.submissionTemplate')}:</h3>
            <SwwcLoadingIndicator type="circular" size="xxsmall" />
         </li>
      );
   }

   return (
      <li>
         <h3 className="text-base font-semibold">{t('job.submissionTemplate')}:</h3>
         <p className="break-words">{t(`computeTypes.${mappings[computeTypeName]}`)}</p>
      </li>
   );
};

/**
 * Sidebar component to display job information
 * @typedef {Object} JobInfoProps
 * @property {Job} job - current job
 *
 * @param {JobInfoProps} props
 * @returns {JSX.Element}
 */
export const JobInfo = ({ job }) => {
   const { t } = useTranslation();
   const { mappings, dataState } = useStream(jobTypesStore.state);

   const debitReport = propOr(null, 'debitReport', job);

   return (
      <ul className="col-start-1 col-end-5 row-start-2 md:col-end-2 md:row-start-1 md:row-end-3 flex flex-col gap-2 md:gap-8 shrink mt-4">
         <h2 className="text-xl font-semibold">{t('job.information')}</h2>
         <ComputeType mappings={mappings} />
         <li>
            <h3 className="text-base font-semibold">{t('job.cost')}:</h3>
            {t('credits.displayed', {
               displayedCredits: isNil(debitReport) ? '0' : debitReport.amount,
            })}
         </li>
         <li>
            <h3 className="text-base font-semibold">{t('labels.runTime')}:</h3>
            {formatRunTime({ debitReport }, t)}
         </li>
         <JobInfoFiles job={job} />
         <VersionDisplay job={job} mappings={mappings} jobTypeState={dataState} />
      </ul>
   );
};
