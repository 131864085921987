/**
 * Icon component for displaying job type icons.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.src - The source URL of the icon image.
 * @param {string} props.jobType - The type of job, used for alt text and title.
 * @returns {React.ReactElement} A div containing the icon image.
 *
 * @example
 * <Icon src="/path/to/icon.svg" jobType="cloud_hpc" />
 */
const Icon = ({ src, jobType }) => (
   <div className="flex justify-center items-center">
      <img src={src} width="24" height="24" alt={jobType} title={jobType} />
   </div>
);

export default Icon;
