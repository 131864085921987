/**
 * This file is used to define the remote modules that will be loaded by the application.
 * Microfrontend developers (i.e. Simcenter X App developers) should add their remote modules here.
 */
import { init } from '@module-federation/enhanced/runtime';
import { indexBy, mergeRight, prop } from 'ramda';

import { RemoteComponent, RemoteModule } from './RemoteModule';
import dev from './envs/dev.js';
import prd from './envs/prd.js';
import stg from './envs/stg.js';

const logStyle = 'color: #3296B9; font-weight: bold;';
const log = (x) => console.log(`%c [SCX] ${x}`, logStyle);

const indexByName = indexBy(prop('name'));
const mergeByName = (arr1) => (arr2) => {
   const indexedArr1 = indexByName(arr1);
   return arr2.map((item) => {
      const match = indexedArr1[item.name];
      if (match) {
         log(`Replacing microfontend '${item.name}' with dynamic override`);
         return mergeRight(match, item);
      }
      log(`Adding microfrontend: ${item.name}`);
      return item;
   });
};

const queryParam = 'remoteOverrides';

function getOverrides() {
   const urlParams = new URLSearchParams(window.location.search);
   return urlParams.get(queryParam);
}

function getDevRemotes() {
   const overrides = getOverrides();
   if (overrides) {
      return fetch(overrides)
         .then((response) => response.json())
         .then(mergeByName(dev))
         .catch((error) => {
            console.error('Error fetching remote overrides', error);
            log('Using default dev remotes');
            return dev;
         });
   }
   return Promise.resolve(dev);
}

/**
 * @returns {Promise<RemoteModuleDefinitionList>}
 */
const getRemotes = () => {
   switch (import.meta.env.VITE_DEPLOYMENT_TYPE) {
      case 'prd':
         return Promise.resolve(prd);
      case 'stg':
         return Promise.resolve(stg);
      default:
         return getDevRemotes();
   }
};

const makeRemoteComponent = (remote) =>
   remote.isReact ? (
      <RemoteComponent modulePath={`${remote.name}/${remote.exposedModule}`} />
   ) : (
      <RemoteModule modulePath={`${remote.name}/${remote.exposedModule}`} name={remote.element} />
   );

/**
 * Initializes the remote modules.
 * @param {JSX.Element} RouteComponent Component which provides the route configuration - should conform to
 * the react-router `Route` interface
 * @returns {Promise<JSX.Element[]>}
 */
export const initRemotes = (RouteComponent) =>
   getRemotes().then((remotes) => {
      init({
         name: '@simcenter-x/host',
         remotes,
      });

      return remotes.reduce(
         (acc, remote) =>
            remote.excludeFromRoutes
               ? acc
               : acc.concat(
                    <RouteComponent key={remote.name} path={remote.name} element={makeRemoteComponent(remote)} />,
                 ),
         [],
      );
   });
