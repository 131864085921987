import { useEffect, useState } from 'react';

import { errors } from '@/store/listing/errors';

import { Toast } from '@/modules/common/Toast';

/**
 * @typedef {Object} ErrorToast
 * @property {string} type - error type
 * @property {string} message - error message
 */

/**
 * Handler that transforms an error into a rendered Toast component
 * @typedef {Object} ToastHandlerProps
 * @property {ErrorToast} item toast content
 *
 * @param {ToastHandlerProps} props
 * @returns {React.ReactElement}
 */
export const ToastHandler = ({ item }) => {
   const [toast, setToast] = useState(null);

   useEffect(() => {
      if (!toast && item) {
         setToast({
            type: item.type,
            description: item.message,
         });
         errors.clearLastError();
      }
   }, [toast, item]);

   return toast ? <Toast toast={toast} variant="error" clearToast={() => setToast(null)} /> : null;
};
