import { path } from 'ramda';

import { GetBalance } from '@/gql';
import { fetchPolicies } from '@/gql/constant';

import { throwWhenError } from '@/store/common/utils';

import { errorTypes } from '@/utils/constants';

const getBalance = (client) =>
   client
      .query(GetBalance, {}, { requestPolicy: fetchPolicies.networkOnly })
      .toPromise()
      .then(throwWhenError(errorTypes.creditsGetError))
      .then(path(['data', 'creditData']));

export default {
   getBalance,
};
