import { createApp } from '@/store/common/pal';
import { createStateStream } from '@/store/common/state';

import { dataStates, INITIAL_PAGE } from '@/utils/constants';

import { rawActions } from './actions';
import { nap } from './nap';

const appState = createStateStream({
   errors: [],
});

export const model = {
   currentPage: INITIAL_PAGE,
   dataState: dataStates.loading,
   workspace: {
      jobs: [],
   },
   selection: [],
   displayedJobs: [],
   totalExistingJobs: 0,
   warnUserRemoteSession: { runningSessions: false, userWantsToBeWarned: true },
   warnUserHPCJob: false,
};

export default {
   app: appState,
};

export const store = createApp(rawActions, model, nap);
