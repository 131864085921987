/**
 * @typedef {import('@/types').File} File
 */
import { useTranslation } from 'react-i18next';
import { useClient } from 'urql';

import { fileStore } from '@/store/files/store';

import { Cell } from './Cell';

/**
 * ImageCell component for displaying image files in a table
 * @typedef {Object} ImageCellProps
 * @property {File} file - File object to display
 *
 * @param {ImageCellProps} prop
 * @returns {React.ReactElement} A table cell component for image files
 */
export const ImageCell = ({ file }) => {
   const client = useClient();
   const { t } = useTranslation();

   const handleClick = () => {
      fileStore.actions.previewFile(client, file);
   };

   return (
      <Cell
         onClick={handleClick}
         cellStyle="cursor-pointer hover:underline decoration-blue-900"
         title={t('files.preview')}
         textStyle="text-blue-900"
         data-accessor="filename"
      >
         {file.filename}
      </Cell>
   );
};
