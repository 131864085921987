import { updateTrimmedValue, updateValue } from '@/utils/jobSubmission';

import { SwwcInputField } from '@/swwc';

/**
 * Component for a text input field for a job's name
 * @typedef {Object} TextInputProps
 * @property {string} label - The label for the input field
 * @property {string} placeholder - The placeholder for the input field
 * @property {() => void} onChange - The function to call when the input value changes
 * @property {string} inputValue - The current value of the input field
 * @property {string} errorMessage - The error message to display
 * @property {boolean} error - Whether the input field has an error
 *
 * @param {TextInputProps} props
 * @returns {React.ReactElement}
 */
export const TextInput = ({ label, placeholder, onChange, inputValue, errorMessage, error }) => {
   return (
      <SwwcInputField
         label={label}
         placeholder={placeholder}
         name="name"
         type="text"
         value={inputValue}
         required
         errorMessage={errorMessage}
         error={error}
         onValueChange={updateValue(onChange)}
         onBlur={updateTrimmedValue(onChange)}
         uncontrolled-mode
      />
   );
};
